import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import styles from './CharityDashboard.module.css';
import { ImpactContext } from '../contexts/ImpactContext';
import { FaSearch, FaLink, FaTimes, FaClock, FaPlus } from 'react-icons/fa';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext';

function CharityDashboard() {
  const [charityData, setCharityData] = useState(null);
  const [error, setError] = useState(null);
  const { user, getAuthHeaders, logout } = useAuth();
  const navigate = useNavigate();

  // Search and linking states
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [selectedCharity, setSelectedCharity] = useState(null);
  const [evidenceFile, setEvidenceFile] = useState(null);
  const [linkingStatus, setLinkingStatus] = useState(null);
  const [linkedCharity, setLinkedCharity] = useState(null);

  useEffect(() => {
    const fetchCharityData = async () => {
      if (!user || !user.isCharity) {
        setError('You must be logged in as a charity to view this dashboard.');
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002'}/api/charities/me`, {
          headers: getAuthHeaders()
        });
        setCharityData(response.data);

        // Fetch linking status
        const statusResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002'}/api/charities/linking-status`, {
          headers: getAuthHeaders()
        });
        setLinkingStatus(statusResponse.data.status);

        // If there's a linked ABN, fetch the charity details
        if (statusResponse.data.linkedABN) {
          const linkedResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002'}/api/search-charities`, {
            params: { q: statusResponse.data.linkedABN }
          });
          if (linkedResponse.data?.result?.records?.length > 0) {
            setLinkedCharity(linkedResponse.data.result.records[0]);
          }
        }
      } catch (err) {
        console.error('Error fetching charity data:', err);
        setError('Failed to load charity data. Please try again later.');
      }
    };

    fetchCharityData();
  }, [user, getAuthHeaders]);

  const searchCharities = useCallback(async (term) => {
    if (!term || term.length < 2) {
      setSearchResults([]);
      return;
    }

    setIsSearching(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002'}/api/search-charities`, {
        params: { q: term }
      });

      if (response.data?.result?.records) {
        setSearchResults(response.data.result.records);
      }
    } catch (err) {
      console.error('Error searching charities:', err);
      setError('Failed to search charities. Please try again.');
    } finally {
      setIsSearching(false);
    }
  }, []);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      if (searchTerm) {
        searchCharities(searchTerm);
      }
    }, 500);

    return () => clearTimeout(delaySearch);
  }, [searchTerm, searchCharities]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleLinkCharity = (charity) => {
    setSelectedCharity(charity);
    setShowLinkModal(true);
  };

  const handleFileChange = (event) => {
    setEvidenceFile(event.target.files[0]);
  };

  const handleSubmitEvidence = async () => {
    if (!evidenceFile || !selectedCharity) return;

    const formData = new FormData();
    formData.append('evidence', evidenceFile);
    formData.append('charityABN', selectedCharity.ABN);
    formData.append('linkingRequestDate', new Date().toISOString());

    try {
      const headers = {
        ...getAuthHeaders(),
        'Content-Type': 'multipart/form-data',
      };
      delete headers['Content-Type']; // Let axios set the correct boundary

      await axios.post(`${process.env.REACT_APP_API_BASE_URL || 'http://localhost:3002'}/api/charities/link-request`, formData, {
        headers: headers
      });

      setLinkingStatus('pending');
      setLinkedCharity(selectedCharity);
      setShowLinkModal(false);
      setSelectedCharity(null);
      setEvidenceFile(null);
      setSearchTerm('');
      setSearchResults([]);
    } catch (err) {
      console.error('Error submitting evidence:', err);
      setError('Failed to submit evidence. Please try again.');
    }
  };

  const renderCharityCard = (charity, isPending = false) => {
    const address = [
      charity.Town_City,
      charity.State,
      charity.Postcode
    ].filter(Boolean).join(', ');

    return (
      <div key={charity.ABN} className={styles.card}>
        <div className={styles.cardContent}>
          <h3 className={styles.cardTitle}>{charity.Charity_Legal_Name}</h3>
          <p className={styles.description}>{address}</p>
          <p className={styles.highlight}>ABN: {charity.ABN}</p>
        </div>
        <div className={styles.cardActions}>
          {isPending ? (
            <button
              className={`${styles.button} ${styles.compact}`}
              disabled
              title="Linking request pending"
            >
              <FaClock /> Pending
            </button>
          ) : (
            <button
              onClick={() => handleLinkCharity(charity)}
              className={styles.iconButton}
              title="Link this charity"
            >
              <FaLink />
            </button>
          )}
        </div>
      </div>
    );
  };

  if (error) {
    return <div className={styles.dashboardContainer}><p className={styles.description}>{error}</p></div>;
  }

  if (!charityData) {
    return <div className={styles.dashboardContainer}><p className={styles.description}>Loading...</p></div>;
  }

  return (
    <div className={styles.dashboardContainer}>
      <header className={styles.header}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <h1 className={styles.title}>Charity Dashboard</h1>
        <button onClick={handleLogout} className={styles.button}>Log Out</button>
      </header>

      <div className={styles.card}>
        <h2 className={styles.cardTitle}>Welcome, {charityData.charityName}</h2>
        <p className={styles.description}><strong>Email:</strong> {charityData.contactEmail}</p>
        <p className={styles.description}><strong>Category:</strong> {charityData.category}</p>
      </div>

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Link Your Charity</h3>
        {linkingStatus === 'pending' && linkedCharity ? (
          <div>
            <p className={styles.description}>Your linking request is being reviewed</p>
            {renderCharityCard(linkedCharity, true)}
          </div>
        ) : !charityData.linkedABN && (
          <>
            <p className={styles.description}>Search for your charity in the Australian Charities database to link it to your dashboard.</p>
            <div className={styles.searchInputWrapper}>
              <FaSearch className={styles.searchIcon} />
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for your charity..."
                className={styles.searchInput}
              />
            </div>
            {isSearching && (
              <div className={styles.description}>Searching...</div>
            )}

            {searchResults.length > 0 && (
              <div className={styles.grid}>
                {searchResults.map(charity => renderCharityCard(charity))}
              </div>
            )}
          </>
        )}
      </div>

      {linkingStatus === 'approved' && (
        <div className={styles.card}>
          <h3 className={styles.cardTitle}>Public Page Status: Approved</h3>
          <button
            onClick={() => navigate(`/charity/${charityData.linkedABN}/edit`)}
            className={styles.button}
          >
            Edit Public Page
          </button>
        </div>
      )}

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Mission Statement</h3>
        <p className={styles.description}>{charityData.missionStatement}</p>
      </div>

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>About Us</h3>
        <p className={styles.description}>{charityData.description}</p>
      </div>

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Donation Statistics</h3>
        <p className={styles.description}>Total Donations: $X,XXX</p>
        <p className={styles.description}>Number of Donors: XXX</p>
      </div>

      <div className={styles.card}>
        <h3 className={styles.cardTitle}>Current Campaigns</h3>
        <ul className={styles.description}>
          <li>Campaign 1</li>
          <li>Campaign 2</li>
        </ul>
      </div>

      <div className={styles.card}>
        <div className={styles.cardActions}>
          <button className={styles.button}>
            <FaPlus /> Create New Campaign
          </button>
        </div>
      </div>

      {showLinkModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <button onClick={() => setShowLinkModal(false)} className={styles.iconButton}>
              <FaTimes />
            </button>
            <h2 className={styles.cardTitle}>Link Your Charity</h2>
            <p className={styles.description}>Please provide evidence that you represent {selectedCharity?.Charity_Legal_Name}</p>
            <div>
              <input
                type="file"
                onChange={handleFileChange}
                accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
                className={styles.fileInput}
              />
              <button
                onClick={handleSubmitEvidence}
                className={styles.button}
                disabled={!evidenceFile}
              >
                Submit Evidence
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CharityDashboard;